.splash{
    display: flex;
    flex-direction: column;
    justify-content: center;

    visibility: hidden;
    opacity: 0;
    margin: 0;
    height: 10vh;
}

.splash.scroll{
    visibility: visible;
    opacity: 100;
    height: 100vh;
}

.splash button{
    font-size: 200%;
}

.logo{
    width: 80%;
    max-width: 800px;
    margin: 1em auto;
}

.logo svg{
    width: 100%;
    height: auto;
}

.home .content{
    min-height: 100vh;
}


.row {
    display: flex;
    justify-content: center;
}

.mouse{
    width: 20px;
    height: 40px;
    border: 4px solid #333;
    border-radius: 60px;
    margin: 50px;
}

.mouse::before{
    content: "";
    display: block;
    position: relative;
    width: 5px;
    height: 5px;
    background: #333;
    border-radius: 50%;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
    animation: mouse 2s infinite;
}

@keyframes mouse {
    from{
        opacity: 1;
        top: 5px;
    }
    to{
        opacity: 0;
        top: 35px;
    }
}

