@font-face {
  font-family:"FA 5 Brands";
  font-style:normal;
  font-weight:400;
  font-display:auto;
  src:url(../fonts/fa-brands-400.woff2)
}

.fab{
  font-family: "FA 5 Brands", serif;font-weight:400
}

@font-face{
  font-family:"FA 5";
  font-style:normal;
  font-weight:900;
  font-display:auto;
  src:url(../fonts/fa-solid-900.woff2);
}

.fa{
  font-family: "FA 5", serif;font-weight:900}

.fa,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.mastodon:before {
  content: "\f4f6"; }

.briefcase:before {
  content: "\f0b1"; }

.microscope:before {
  content: "\f610"; }

.github:before {
  content: "\f09b"; }

.gamepad:before {
  content: "\f11b"; }

.blog:before {
  content: "\f781"; }

.home:before{
  content: "\f015"; }

.twitter:before {
  content: "\f099"; }

.threads:before{
  content: "\e618"}

.uni:before{
  content: "\f19c"; }
