.navbar {
    display: flex;
    align-items: center;
    background-color: #43494e;
    box-shadow: 0 0 20px 1px rgb(0, 0, 0);
    margin-bottom: 2em;
    padding-left: 0.5em;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 100;
    transition: opacity 150ms cubic-bezier(0.1, 0.7, 0.6, 0.9);
}

.title {
    font-size: 200%;
    color: rgb(255, 255, 255);
}

.scroll{
    visibility: hidden;
    opacity: 0;
    height: 0;
    margin: 0;
}

.logo svg{
    width: auto;
    height: 6vh;
}


.buttons {
    font-size: 100%;
    display: flex;
}

.buttons button {
    padding: 0.2em;
    background: transparent;
    margin-right: 0.5em;
    margin-left: 0.5em;
    font-size: 150%;
    box-shadow: 0 0 45px 8px rgba(0, 0, 0, 0.14);
}

@media only screen and (max-width: 750px) {
    /* For mobile phones: */
    .navbar{
        flex-wrap: wrap;
        justify-content: center;
    }

    .navbar a {
        flex-basis: 100%;
        text-align: center;
    }

    .logo svg{
        height: 10vh;
    }

    .buttons button{
        background: transparent;
        margin: 10px 3vw;
        font-size: 200%;
        min-width: 40vw;
    }

    .buttons{
        justify-content: center;
        flex-wrap: wrap;
    }
}