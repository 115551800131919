.button {
    border: none;
    padding: 0;
    border-radius: 3px;
    font-size: 130%;
    transition: 0.3s;
    color: white;
    display: flex;
    background-color: transparent;
    font-family: 'Bebas Neue', serif;
    margin-right: 1em;
    margin-left: 1em;
}

.button:hover{
    background:rgba(255,255,255,0.4);
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.button:focus{
    outline: none;
}

.icon{
    padding: 10px 0.5ch;
    background-color: white;
    border-radius: 3px;
    color: black;
}

.icon.both{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.name{
    display: grid;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.background{
    height: 100%;
    align-items: center;
    border-radius: 3px;
    grid-column-start: 1;
    grid-row-start: 1;
}

.background.both{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.text{
    opacity: 1;
    grid-column-start: 1;
    grid-row-start: 1;
    z-index: 1;
    padding: 0 1em;
}

.threads{
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fdf49781 0%, #fdf49781 5%, #fd584981 45%,#d6249f81 60%,#285AEB81 90%);
    text-shadow: 0 0 20px #000;
    opacity: 1;
}